@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    min-width: 280px;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body,
  #root,
  .ant-layout {
    height: 100%;
  }

  * {
    font-family:
      Pretendard,
      -apple-system,
      BlinkMacSystemFont,
      system-ui,
      Roboto,
      'Helvetica Neue',
      'Segoe UI',
      'Apple SD Gothic Neo',
      'Noto Sans KR',
      'Malgun Gothic',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      sans-serif;
  }
}

.ant-select {
  min-width: 120px;
  height: 32px;

  &-selector {
    padding: 0 16px !important;
  }

  &-open {
    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }

  &-arrow {
    inset-inline-end: 16px !important;

    .anticon {
      color: rgba(0, 0, 0, 0.88);
    }
  }
}

.ant-tooltip {
  max-width: unset;
}

.ant-timeline {
  margin-top: 7px;
  margin-bottom: -11px;

  &-item {
    &-last {
      .ant-timeline-item-content {
        min-height: unset !important;
      }
    }

    &-head {
      left: -2.5px;
      top: -2.5px;
      width: 14px !important;
      height: 14px !important;
      border-color: #fff !important;
    }

    &-tail {
      border-style: dashed !important;
    }
  }
}

.ant-tag {
  margin: 0 !important;
}

.ant-picker-dropdown-range {
  table thead tr th,
  table tbody tr .ant-picker-cell-in-view {
    &:first-child {
      @apply text-red-500;
    }

    &:last-child {
      @apply text-blue-500;
    }
  }
}

.ant-picker-range {
  .ant-picker-input:first-child:has(input[value=''])
    + .ant-picker-range-separator {
    display: none !important;
  }

  .ant-picker-input:not(:first-child):has(input[value='']) {
    width: 0 !important;
    opacity: 0;
    flex: 0 !important;
    background: red;
  }
}

.ant-table-wrapper {
  table {
    th,
    td {
      white-space: nowrap;
    }

    thead {
      th.ant-table-column-sort {
        background: #fafafa !important;
      }
    }

    tbody {
      td.ant-table-column-sort {
        background: transparent !important;
      }
    }

    tfoot {
      th,
      td {
        @apply font-bold;
        background: #fbfbfb;
      }
    }
  }
}

.ant-segmented {
  max-width: 100%;
  overflow-x: auto;
}
